import React, { useState, useEffect } from "react";
import { InputLabel, MenuItem, Select, FormControl, Button, Grid, TextField, FormControlLabel, Checkbox } from "@mui/material";

const TargetGame = (props) => {
    const handleChangeTeam = (e) => {
        props.setValue({ ...props.value, [e.target.name]: e.target.value })
    }
    const handleChangePlayer = (e) => {
        props.setValue({ ...props.value, [e.target.name]: e.target.value })
    }
    const handleChangeShowAll = (e) => {
        props.setValue({ ...props.value, [e.target.name]: e.target.checked })
    }
    const handleChangeShowOpponent = (e) => {
        props.setValue({ ...props.value, [e.target.name]: e.target.checked })
    }
    return (
        <div>
            <Grid container>
                <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <TextField label="Team" size="small" name="team" value={props.value.team} onChange={handleChangeTeam}>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <TextField label="Player" size="small" name="player" value={props.value.player} onChange={handleChangePlayer}>
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item alignItems="stretch" style={{ display: "flex" }}>
                    <FormControlLabel control={<Checkbox checked={props.value.showAll} name="showAll" onChange={handleChangeShowAll} />} label="display no targets found game" />
                </Grid>
                <Grid item alignItems="stretch" style={{ display: "flex" }}>
                    <FormControlLabel control={<Checkbox checked={props.value.showOpponent} name="showOpponent" onChange={handleChangeShowOpponent} />} label="search targets in opponent teams" />
                </Grid>
            </Grid>
        </div>
    );
}

export default TargetGame;