import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Dialog, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import PlayArrow from '@mui/icons-material/PlayArrow';
import { FaTable, FaExternalLinkAlt } from 'react-icons/fa';
import { pink, grey } from '@mui/material/colors';

const Game = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    props.setDisplayed(props.index)
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={12}>
      <Paper sx={{
        backgroundColor: '#1A2027',
        padding: 0.5,
        color: "text.primary"
      }}>
        <Grid container>
          <Grid item xs={0} md={0.5}>
            <Button sx={{ visibility: props.Bakkes.state !== "ready" ? "hidden" : null, height: "100%", backgroundColor: (props.Bakkes.state == "ready") ? (props.replay.isPlayed ? pink[100] : grey[100]) : "grey", display: { xs: 'none', md: 'flex' }, color: "black", alignItems: "center", justifyContent: "center", cursor: "pointer", minWidth: "0px", "&:hover": { backgroundColor: pink[200] }, p: 0 }} onClick={handleClick}>
              <PlayArrow sx={{
                margin: "auto",
                fontSize: "40px",
              }} />
            </Button>
            {props.detail.frames?.length > 0 ?
              <TableContainer component={Dialog} open={open} onClose={handleClose}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Player</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.detail.frames.map((frame, index) => (
                      <TableRow key={index}>
                        <TableCell>{frame.is_overtime ? "+" : null}{Math.floor(frame.seconds_remaining / 60)}:{String(frame.seconds_remaining % 60).padStart(2, '0')}</TableCell>
                        <TableCell>{props.detail.players.blue.find(player => player.id == frame.player)?.name || props.detail.players.orange.find(player => player.id == frame.player)?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> : null}
          </Grid>
          <Grid item xs={12} md={11.5} sx={{ px: 1 }} textAlign="left">
            <Box sx={{ color: "text.secondary", fontSize: "12px", my: 0.5 }}> {props.detail.group.join(" / ")}</Box>
            <Box sx={{ color: "dodgerblue", fontSize: "16px", fontWeight: 'bold', my: 0.5 }}> {props.replay.replay_title}</Box>
            <Button variant="contained" size="small" sx={{ fontSize: "12px", py: 0, px: 1, mx: 1, my: 0.5, backgroundColor: "#444444", color: "white", textTransform: "none", "&:hover": { backgroundColor: "#666666" } }} onClick={handleOpen}><Box sx={{ pr: 1 }}>targets: {props.detail.frames ? props.detail.frames?.length : "N/A"}</Box><FaTable /></Button>
            <Button variant="contained" size="small" sx={{ fontSize: "12px", py: 0, px: 1, mx: 1, my: 0.5, backgroundColor: "#444444", color: "white", textTransform: "none", "&:hover": { backgroundColor: "#666666" } }} href={"https://ballchasing.com/replay/" + props.replay.id}><Box sx={{ pr: 1 }}>ballchasing</Box><FaExternalLinkAlt /></Button>
            <Grid container sx={{ lineHeight: 1, my: 0.5 }}>
              <Grid item xs={6}>
                <Box sx={{ color: "deepskyblue", fontSize: "12px", my: 0.5 }}>{props.replay.blue.name || "BLUE"}</Box>
                {props.replay && props.replay.blue.players ? props.replay.blue.players.map((player, index) => (
                  <Box key={index} sx={{ mx: 1, my: 0.5, fontSize: "12px", display: { xs: "block", md: "inline" } }}>{player.name}</Box>
                )) : null}
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ color: "orange", fontSize: "12px", my: 0.5 }}>{props.replay.orange.name || "ORANGE"}</Box>
                {props.replay && props.replay.orange.players ? props.replay.orange.players.map((player, index) => (
                  <Box key={index} sx={{ mx: 1, my: 0.5, fontSize: "12px", display: { xs: "block", md: "inline" } }}>{player.name}</Box>
                )) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    </Grid >
  );
};

export default Game;