import React, { useState, useEffect } from "react";
import { InputLabel, MenuItem, Select, FormControl, Grid } from "@mui/material";

const Group = (props) => {
  return (
    <Grid item>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          value={props.value}
          onChange={e => props.onChange(e, props.index)}
          data-group-num={0}
          error={props.required && props.value === ""}
          displayEmpty
        >
          {!props.required ? (<MenuItem value=""><em>&nbsp;</em></MenuItem>) : null}
          {props.items.map((item, index) => (
            <MenuItem key={`${index}-${item}`} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default Group;