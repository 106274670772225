import React, { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import Target from "./Target";
import TargetGroup from "./TargetGroup";
import TargetGame from "./TargetGame";
import { useSearchParams } from "react-router-dom";
import { Details } from "./TargetConst";

const Search = (props) => {
    const [targetValue, setTargetValue] = useState({
        target: "",
        detail: []
    })
    const [groupValue, setGroupValue] = useState([
        {
            value: "",
            items: [],
            required: true,
        }
    ])
    const [endOptions, setEndOptions] = useState({})
    const [filterValue, setFilterValue] = useState({
        team: "",
        player: "",
        showAll: false,
        showOpponent: false
    })
    const [searchParams, setSearchParams] = useSearchParams();

    const search = () => {
        const index = groupValue.findIndex(group => group.required == false)
        const group = groupValue.filter((group) => group.value !== "").map((group) => group.value)
        if (index === -1) {
            // validation error
        } else {
            const replaysPath = "json/" + group.slice(0, index).join("/") + "/replays_" + targetValue.target + ".json"
            const detailsPath = "json/" + group.slice(0, index).join("/") + "/details_" + targetValue.target + ".json"
            props.search(replaysPath, detailsPath, targetValue, group.slice(index - 1), filterValue)

            let param = {}
            param["target"] = targetValue.target
            for (const groupIndex in targetValue.detail) {
                for (const itemIndex in targetValue.detail[groupIndex].items){
                    param["t" + groupIndex + "-" + itemIndex] = targetValue.detail[groupIndex].items[itemIndex].checked
                }
            }
            for (const index in group) {
                param["g" + index] = group[index]
            }
            param["f1"] = filterValue.team
            param["f2"] = filterValue.player
            param["f3"] = filterValue.showAll
            param["f4"] = filterValue.showOpponent
            setSearchParams(param)
        }
    }
    const handleSubmit = evt => {
        evt.preventDefault();
        search()
    };

    useEffect(() => {
        let targetDetail = Details[searchParams.get('target') ?? ""];
        targetValue.target = searchParams.get('target') ?? "";
        targetValue.detail = targetDetail;
        let groupIndex = 0;
        while (true) {
            let itemIndex = 0;
            while (true) {
                if (searchParams.has("t" + groupIndex + "-" + itemIndex)) {
                    targetValue.detail[groupIndex].items[itemIndex].checked = JSON.parse(searchParams.get("t" + groupIndex + "-" + itemIndex));
                    itemIndex++;
                } else{
                    break;
                }
            }
            if(itemIndex == 0){
                break
            }else{
                groupIndex++;
            }
        }
        setTargetValue(targetValue)

        filterValue.team = searchParams.get('f1') ?? "";
        filterValue.player = searchParams.get('f2') ?? "";
        filterValue.showAll = searchParams.get('f3') ? JSON.parse(searchParams.get('f3')) : false;
        filterValue.showOpponent = searchParams.get('f4') ? JSON.parse(searchParams.get('f4')) : false;
        setFilterValue(filterValue)

        let groups = []
        let i = 0
        while (true) {
            if (searchParams.has("g" + i)) {
                groups.push(searchParams.get('g' + i))
            } else {
                break
            }
            i++;
        }

        let urls = []
        for (let i = 0; i < groups.length + 1; i++) {
            urls.push('/json/' + groups.slice(0, i).join('/') + '/options.json')
        }

        Promise.all(
            urls.map(url => fetch(url))
        ).then(
            async responses => {
                for (let i = 0; i < responses.length; i++) {
                    await responses[i].json().then(res => {
                        if (res.end) {
                            setEndOptions(res.options)
                            let items = res.options
                            for (let j = i; j < groups.length + 1; j++) {
                                if ("list" in items) {
                                    const group = {
                                        value: groups[j] ?? "",
                                        items: items.list,
                                        required: false,
                                    }

                                    if (j in groupValue) {
                                        groupValue[j] = group
                                    } else {
                                        groupValue.push(group)
                                    }
                                }
                                items = items[groups[j]]
                            }
                            setGroupValue([...groupValue])
                        } else {
                            const group = {
                                value: groups[i] ?? "",
                                items: res.options,
                                required: !res.end
                            }
                            if (i in groupValue) {
                                groupValue[i] = group
                            } else {
                                groupValue.push(group)
                            }
                            setGroupValue([...groupValue])
                        }
                        return res.end
                    }).then((end) => {
                        if (end) {
                            search()
                        }
                    }).catch((err) => {
                    });
                }
            }
        ).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Typography>
                Target
            </Typography>
            <Target
                setValue={setTargetValue}
                value={targetValue}
            />
            <Typography>
                Event
            </Typography>
            <TargetGroup
                setValue={setGroupValue}
                value={groupValue}
                endOptions={endOptions}
                setEndOptions={setEndOptions}
            />
            <Typography>
                Filter
            </Typography>
            <TargetGame
                setValue={setFilterValue}
                value={filterValue}
            />
            <Button type="submit" variant='contained'>Search</Button>
        </form>

    );
}

export default Search;