import React, { useState, useEffect } from 'react'
import { Box, Button, Container } from '@mui/material';
import Search from '../modules/gameSelecter/Search';
import Result from '../modules/result/Result';
import YouTube from "react-youtube";

export default function Root() {
  // const [loading, setLoading] = useState(true);
  // const [errorMessage, setErrorMessage] = useState(null);
  const [target, setTarget] = useState();
  const [replays, setReplays] = useState([]);
  const [details, setDetails] = useState({});
  const [filter, setFilter] = useState();

  const search = (replaysPath, detailsPath, target, groups, filter) => {
    // setLoading(true);
    // setErrorMessage(null);
    setTarget(target);
    setFilter(filter);

    Promise.all([
      fetch(replaysPath),
      fetch(detailsPath)
    ]).then(
      responses => Promise.all(responses.map(r => r.json()))
    ).then(([replays, details]) => {
      let filtered_replays = replays.filter(value => {
        let found = true;
        if (groups.length > details[value.rocket_league_id].group.length) {
          return false;
        }
        for (let i in groups) {
          if (groups[i] != details[value.rocket_league_id].group[i]) {
            return false;
          }
        }
        let is_team_matched = !filter.team;
        is_team_matched = is_team_matched || (!value.blue.name ? false : value.blue.name.match(filter.team)) || (!value.orange.name ? false : value.orange.name.match(filter.team));
        let is_player_matched = !filter.player;
        is_player_matched = is_player_matched || (value.blue.players.some(player => player.name.match(filter.player))) || (value.orange.players.some(player => player.name.match(filter.player)));
        return is_team_matched && is_player_matched;
      });

      setReplays(filtered_replays)
      setDetails(details)
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container sx={{ pt: 2, display: 'flex' }} maxWidth={false}>
      <Box maxWidth={"lg"} sx={{ mx: 'auto', width: '100%' }}>
        <Search search={search} />
        <Result replays={replays} details={details} target={target} filter={filter} />
      </Box>
      <Box sx={{ display: { xs: 'none', xl: 'flex' }, minWidth: '480px' }}>
        <YouTube videoId="TJ-_Vf-KnVc" opts={{ width: 480, height: 270 }}></YouTube>
      </Box>
    </Container>
  );
}