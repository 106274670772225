import React, { useState, useEffect } from "react";
import { MenuItem, Select, FormControl, FormControlLabel, FormLabel, FormGroup, Button, Checkbox, Grid, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, } from "@mui/material";
import { Details, TargetName, DetailInfo } from "./TargetConst";
import TargetDetailInfo from "./TargetDetailInfo";

const TargetDetail = (props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle color="primary">{props.target ? TargetName[props.target.target] : null}</DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6">Additional Conditions</Typography>
                <Box sx={{ p: 1, pb: 2 }}>
                    {/* <DialogContentText> */}
                        {props.target.detail.map((detail, groupIndex) => (
                            <FormControl key={groupIndex} sx={{ mb: 1 }} component="fieldset" fullWidth variant="standard">
                                <FormLabel component="legend">{detail.group}</FormLabel>
                                <FormGroup row>
                                    {detail.items.length > 0 ? detail.items.map((item, itemIndex) => (
                                        <Box key={groupIndex + "-" + itemIndex} sx={{ mr:2 }}>
                                            <FormControlLabel sx={{ mr:0 }} control={<Checkbox checked={item.checked} name={item.name} key={groupIndex + "-" + itemIndex} onChange={(e) => props.handleChange(groupIndex, itemIndex, e)} />} label={item.value} />
                                            <TargetDetailInfo title={item.value} description={item.description}></TargetDetailInfo>
                                        </Box>
                                    )) : <Box sx={{ p: 1 }}></Box>}
                                </FormGroup>
                            </FormControl>
                        ))}
                    {/* </DialogContentText> */}
                </Box>
                <Typography variant="h6">Detection Accuracy</Typography>
                <Box sx={{ p: 1, pb: 2 }}>
                {DetailInfo[props.target.target].accuracy}
                </Box>
                <Typography variant="h6">Detection Conditions</Typography>
                <Box sx={{ p: 1, pb: 2 }}>
                    {DetailInfo[props.target.target].detection}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TargetDetail;