// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import Header from './components/modules/Header'
import Replay from './components/pages/Replay'
import Root from './components/pages/Root'

const theme = createTheme({
  palette: {
    mode: "dark"
  }
})

function App() {
  return (
    // <div className="App">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      {/* <Button variant="contained" color="primary">
        Hello World
      </Button> */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Root />} />
          <Route path="/replay/:id" element={<Replay />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
