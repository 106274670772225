import React, { useState, useEffect, useRef } from "react";
import { InputLabel, MenuItem, Select, FormControl, Button, Grid, TextField } from "@mui/material";
import Group from "./Group";

const TargetGroup = (props) => {

    return (
        <div>
            <Grid container>
                {props.value.map((group, index) => (
                    <Group key={index} index={index} value={group.value} items={group.items} required={group.required} onChange={(e, index) => {
                        let newGroupValue = props.value.slice(0, index + 1)
                        newGroupValue[index].value = e.target.value
                        const endIndex = newGroupValue.findIndex(group=>group.required == false)
                        if(endIndex !== -1){
                                let items = props.endOptions
                                for(let i=endIndex; i<newGroupValue.length; i++){
                                    items = items[newGroupValue[i].value]
                                }
                                if(items && "list" in items){
                                    newGroupValue.push({
                                        value: "",
                                        items: items.list,
                                        required: false,
                                    })
                                }
                                props.setValue([...newGroupValue])
                        }else{
                            fetch(`/json/${newGroupValue.map((group)=>group.value).join("/")}/options.json`)
                                .then(response => response.json())
                                .then(jsonResponse => {
                                    const items = jsonResponse.end ? jsonResponse.options.list : jsonResponse.options
                                    if(jsonResponse.end) props.setEndOptions(jsonResponse.options)
                                    if(items){
                                        newGroupValue.push({
                                            value: "",
                                            items: items,
                                            required: !jsonResponse.end,
                                        })
                                    }
                                    props.setValue([...newGroupValue])
                                });
                        }
                    }}></Group>
                ))}
            </Grid>
        </div>
    );
}

export default TargetGroup;