import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from "@mui/material";

const Rank = (props) => {
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])

  function filterFramesFromDetail(detail) {
    let frames = detail.frames;

    if (frames) {
      frames = frames.filter(frame => {
        const team = props.filter.showOpponent ? (frame.team == "orange" ? "blue" : "orange") : frame.team;
        let team_matched = true;
        if (props.filter.team) {
          team_matched = detail.teams[team].match(props.filter.team);
        }

        let player_matched = true;
        if (props.filter.player) {
          player_matched = detail.players["orange"].find(a => a.id == frame.player && a.name.match(props.filter.player)) || detail.players["blue"].find(a => a.id == frame.player && a.name.match(props.filter.player))
        }

        return team_matched && player_matched
      })

      for (const detail of props.target.detail) {
        const isAll = detail.items.every((detail) => !detail.checked)
        if (!isAll && detail.group != "result") {
          frames = frames.filter(frame => {
            return detail.items.every((item) => !item.checked) || detail.items.some(item => item.checked && (detail.key ? frame[detail.key] == item.name : frame[item.name]));
          });
        }
      }

      frames = frames.sort((a, b) => a.frame - b.frame)
      detail.frames = frames;
    }
    return detail
  }

  useEffect(() => {
    if (!props.target) return
    const columns = [
      {
        field: 'no',
        headerName: 'No.',
        valueGetter: (params) =>
          `${params.api.getRowIndex(params.row.id) + 1}`,
        width: 50
      },
      { field: 'player', headerName: 'PLAYER NAME', width: 250 },
      {
        field: 'total', headerName: 'TOTAL'
        , type: 'number',
      },
      {
        field: 'games',
        headerName: 'GAMES',
        type: 'number',
      },
      // {
      //   field: 'goals',
      //   headerName: 'GOALS',
      //   type: 'number',
      // },
      // {
      //   field: 'assists',
      //   headerName: 'ASSISTS',
      //   type: 'number',
      // },
      // {
      //   field: 'keeps',
      //   headerName: 'KEEPS',
      //   type: 'number',
      // },
      // {
      //   field: 'passes',
      //   headerName: 'PASSES',
      //   type: 'number',
      // },
      // {
      //   field: 'goaleds',
      //   headerName: 'GOALEDS',
      //   type: 'number',
      // },
      // {
      //   field: 'A',
      //   headerName: 'A',
      //   type: 'number',
      // },
      // {
      //   field: 'I',
      //   headerName: 'I',
      //   type: 'number',
      // },
      // {
      //   field: 'L',
      //   headerName: 'L',
      //   type: 'number',
      // },
      // {
      //   field: 'R',
      //   headerName: 'R',
      //   type: 'number',
      // },
      // {
      //   field: 'touchinatacking',
      //   headerName: 'touchinatacking',
      //   type: 'number',
      // },
      // {
      //   field: 'touchedindefensive',
      //   headerName: 'touchedindefensive',
      //   type: 'number',
      // },
      ...props.target.detail.find(target => target.group == "result").items.map(item => {
        return {
          field: item.tableName,
          headerName: item.tableName,
          type: "number"
        }
      })
    ];
    setColumns(columns)
  
    // const filtered_details = props.details;

    // for (const key in filtered_details) {
    //   filtered_details[key] = filterFramesFromDetail({ ...filtered_details[key] })
    // }

    let ranking = [];

    for (let i = 0; i < props.replays.length; i++) {
      const det =  filterFramesFromDetail(props.details[props.replays[i].rocket_league_id])
      let frames = det.frames;


      // playerを絞る
      let players = []
      for(const team of ["orange", "blue"]){
        if(!props.filter.team || (props.replays[i][team].name && props.replays[i][team].name.match(props.filter.team))){
          const teamColor = props.filter.showOpponent ? (team == "orange" ? "blue" : "orange") : team;
          if(props.replays[i][teamColor].players){
            players = [...players, ...props.replays[i][teamColor].players]
          }
        }
      }
      // Object.keys(props.replays[i].teams).filter(team => {
      //   !props.filter.team || props.replays[i].teams[team].match(props.filter.team);
      // }).map(team => {
      //   const team = props.filter.showOpponent ? (team == "orange" ? "blue" : "orange") : team;
      // })

      // let players = [props.replays[i].orange.players, ...props.replays[i].blue.players]
      if (props.filter.player) {
        players = players.filter(player => {
          return player.name.match(props.filter.player)
        })
      }

      for (const player of players) {
        const r_index = ranking.findIndex(a => a.id == player.id.id)
        if (r_index != -1) {
          ranking[r_index].games++;
        } else {
          ranking.push({
            id: player.id.id,
            count: 0,
            // counts: props.target.target == "counter" ? 0 : null,
            names: new Set([player.name]),
            games: 1,
            // goals: 0,
            // // assists: 0,
            // // keeps: 0,
            // // passes: 0,
            // goaleds: 0,
            // A: 0,
            // I: 0,
            // L: 0,
            // R: 0,
            // touchinatacking: 0,
            // touchedindefensive: 0
            ...props.target.detail.find(target => target.group == "result").items.reduce((obj, item) => {
              return {...obj, [item.tableName]: 0}
            }, {})
          })
        }
      }

      if (frames) {
        for (let j = 0; j < frames.length; j++) {
          const b_id = det.players.blue.findIndex(a => a.id == frames[j].player)
          const o_id = det.players.orange.findIndex(a => a.id == frames[j].player)
          const player_name = b_id != -1 ? det.players.blue[b_id].name : det.players.orange[o_id].name
          const r_index = ranking.findIndex(a => a.id == frames[j].player)
          if (r_index == -1) {

          } else {
            ranking[r_index].count++
            ranking[r_index].names.add(player_name)
            // if (frames[j]["goal"]) ranking[r_index].goals++;
            // // if (frames[j]["assist"]) ranking[r_index].assists++;
            // // if (frames[j]["keep"]) ranking[r_index].keeps++;
            // // if (frames[j]["pass"]) ranking[r_index].passes++;
            // if (frames[j]["goaled"]) ranking[r_index].goaleds++;
            // if (frames[j]["type"] == "A") ranking[r_index].A++;
            // if (frames[j]["type"] == "I") ranking[r_index].I++;
            // if (frames[j]["type"] == "L") ranking[r_index].L++;
            // if (frames[j]["type"] == "R") ranking[r_index].R++;
            // if (frames[j]["touch_in_atacking"]) ranking[r_index].touchinatacking++;
            // if (frames[j]["touched_in_defensive"]) ranking[r_index].touchedindefensive++;
            if(props.target.target != "counter"){
              for(const item of props.target.detail.find(target => target.group == "result").items){
                if (frames[j][item.name]) ranking[r_index][item.tableName]++;
              }
            }
          }
          if(props.target.target == "counter"){
            const r_index2 = ranking.findIndex(a => a.id == frames[j].shot_player)
            if (r_index2 == -1) {
  
            } else {
              if(props.target.target == "counter"){
                for(const item of props.target.detail.find(target => target.group == "result").items){
                  if (frames[j][item.name]) ranking[r_index2][item.tableName]++;
                }
              }
            }
          }
        }
      }
    }
    ranking.sort((a, b) => b.count - a.count)

    const rows = ranking.map((player, index) => {
      return {
        "id": index,
        "player": [...player["names"]].join(", "),
        "total": player["count"],
        "games": player["games"],
        // "total / games": (player["count"]/player["games"]).toFixed(3),
        // "goals": player["goals"],
        // // "assists": player["assists"],
        // // "keeps": player["keeps"],
        // // "passes": player["passes"],
        // "goaleds": player["goaleds"],
        // "A": player["A"],
        // "I": player["I"],
        // "L": player["L"],
        // "R": player["R"],
        // "touchinatacking": player["touchinatacking"],
        // "touchedindefensive": player["touchedindefensive"],
        // "goals / total": (player["count"] ? player["goals"]/player["count"] : 0).toFixed(3), 
        // "assists / total": (player["count"] ? player["assists"]/player["count"] : 0).toFixed(3), 
        // "keeps / total": (player["count"] ? player["keeps"]/player["count"] : 0).toFixed(3), 
        // "passes / total": (player["count"] ? player["passes"]/player["count"] : 0).toFixed(3), 
        // "goals / games": (player["goals"]/player["games"]).toFixed(3), 
        // "assists / games": (player["assists"]/player["games"]).toFixed(3), 
        // "keeps / games": (player["keeps"]/player["games"]).toFixed(3), 
        // "passes / games": (player["passes"]/player["games"]).toFixed(3), 
        ...props.target.detail.find(target => target.group == "result").items.reduce((obj, item) => {
          return {...obj, [item.tableName]: player[item.tableName]}
        }, {})
      }
    })
    setRows(rows)
  }, [props])

  return (
    <Box sx={{ height: 800}}>
      <DataGrid
        rows={rows}
        columns={columns}
        // autoHeight
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
};

export default Rank;