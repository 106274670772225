import * as React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Games from './Games';
import Rank from './Rank';

export default function LabTabs(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ width: '100%', typography: 'body1', display: (props.replays || props.details) ? "" : "none", minHeight: "1000px", px: {xs: 0} }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Games" value="1" />
            <Tab label="Ranking" value="2" />
            <Tab label="Statistics" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: {xs: 0} }}>
          <Games replays={props.replays} details={props.details} target={props.target} filter={props.filter}/>
        </TabPanel>
        <TabPanel value="2" sx={{ px: {xs: 0} }}>
          
        <Rank  replays={props.replays} details={props.details} target={props.target} filter={props.filter}/>
        </TabPanel>
        <TabPanel value="3" sx={{ px: {xs: 0} }}>Under consideration</TabPanel>
      </TabContext>
    </Container>
  );
}