import { MenuItem, Select, FormControl, FormControlLabel, Button, Checkbox, Grid, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, } from "@mui/material";

const TargetName = {
  "musty": "Musty (Breezi) Flick",
  "kickoff": "Kickoff"
}

const Details = {
  "musty": 
  [
    {
      group: "initial formation",
      items: []
    },
    {
      group: "touch type",
      items: []
    },
    {
      group: "result",
      key: null,
      items: [
        { value: "goal", name: "goal", checked: false, tableName: "GOALS", description: <Box>Scoring a goal without losing possession after musty (breezi) flick</Box> },
        { value: "assist", name: "assist", checked: false, tableName: "ASSISTS", description: <Box>Assisting a goal by or after musty (breezi) flick</Box> },
        { value: "keep", name: "keep", checked: false, tableName: "KEEPS", description: <Box>Possessing the ball after musty (breezi) flick</Box> },
        { value: "pass", name: "pass", checked: false, tableName: "PASSES", description: <Box>Passing the ball by pr after musty (breezi) flick</Box> },
      ]
    },
  ]
  ,
  "kickoff": [
    {
      group: "initial formation",
      key: "type",
      items: [
        {
          value: "A-form", name: "A", checked: false, description: <Box
            component="img"
            sx={{
              width: 400,
            }}
            alt="A-form"
            src="/img/A-form.png"
          />
        },
        {
          value: "I-form", name: "I", checked: false, description: <Box
            component="img"
            sx={{
              width: 400,
            }}
            alt="I-form"
            src="/img/I-form.png"
          />
        },
        {
          value: "L-form", name: "L", checked: false, description: <Box
            component="img"
            sx={{
              width: 400,
            }}
            alt="L-form"
            src="/img/L-form.png"
          />
        },
        {
          value: "R-form", name: "R", checked: false, description: <Box
            component="img"
            sx={{
              width: 400,
            }}
            alt="R-form"
            src="/img/R-form.png"
          />
        },
      ]
    },
    {
      group: "touch type",
      items: []
    },
    {
      group: "result",
      key: null,
      items: [
        { value: "goal (for)", name: "goal", checked: false, tableName: "GOALS(F)", description: <Box>Scoring a goal without losing team possession from kickoff</Box> },
        { value: "goal (against)", name: "goaled", checked: false, tableName: "GOALS(A)", description: <Box>Conceding a goal without team possession from kickoff</Box> },
        { value: "good-attack (for)", name: "touch_in_atacking", checked: false, tableName: "G-ATTACKS(F)", description: <Box>Touching a ball in the atacking third without losing team possession from kickoff</Box> },
        { value: "good-attack (against)", name: "touched_in_defensive", checked: false, tableName: "G-ATTACKS(A)", description: <Box>Getting a ball touched in the defensive third without team possession from kickoff</Box> },
      ]
    },
  ],
  "": [],
  "counter": 
  [
    {
      group: "initial formation",
      items: []
    },
    {
      group: "touch type",
      items: []
    },
    {
      group: "result",
      key: null,
      items: [
        { value: "goal", name: "goal", checked: false, tableName: "GOALS", description: <Box>Scoring a goal without losing possession after musty (breezi) flick</Box> },
        { value: "shot", name: "team", checked: false, tableName: "LAST HITS", description: <Box>Scoring a goal without losing possession after musty (breezi) flick</Box> },
      ]
    },
  ]
};

const DetailInfo = {
  "musty": {
    "conditions": {},
    "accuracy": 
    <Box>
      <DialogContentText>
        Precision: ~80%
      </DialogContentText>
      <DialogContentText>
        Recall: ~95%
      </DialogContentText>

    </Box>
  ,
    "detection":
      <Box>
        <DialogContentText>
          It's detected when satisfying following six conditions.
        </DialogContentText>
        <Box component="ol" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <Box component="li">The flip is not side flip and front flip.</Box>
          <Box component="li">The angle formed by a vector (0, 0, 1) and the direction of the car top at the start of the flip is 0° to 90°.</Box>
          <Box component="li">The angle formed by the car movement diredtion and the direction to the car front at the start of the flip is 90° to 180°.</Box>
          <Box component="li">The angle formed by a vector (0, 0, 1) and the direction to the car left side at the start of the flip is 45° to 135°.</Box>
          <Box component="li">The angle formed by the ball movement direction and the car's one at the start of the flip is 0° to 90°.</Box>
          <Box component="li">The angle formed by the direction to the ball from the car and the direction to the car top at the hit is 0° to 30°.</Box>
        </Box>
      </Box>
  },
  "kickoff": {
    "conditions": {},
    "accuracy":
      <Box>
        <DialogContentText>
          -
        </DialogContentText>

      </Box>
    ,
    "detection":
      <Box>
        <DialogContentText>
          -
        </DialogContentText>
      </Box>

  },
  "counter": {
    "conditions": {},
    "accuracy":
      <Box>
        <DialogContentText>
          -
        </DialogContentText>

      </Box>
    ,
    "detection":
      <Box>
        <DialogContentText>
          -
        </DialogContentText>
      </Box>

  }, "":{}
}

export {
  TargetName, Details, DetailInfo,

}