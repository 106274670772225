let Bakkes = {}

const bakkesViewer = function () {
    var leaving = false,
        first = true,
        init = true;

    function sniffBakkesViewer() {
        // console.log("probing for rllab replay player bakkesmod plugin...");
        Bakkes.state = "sniff0";
        Bakkes.webSocket = new WebSocket("ws://localhost:20425");
        Bakkes.webSocket.addEventListener("error", function (event) {
            // console.log("got error", {
            //     state: Bakkes.state,
            //     event: event
            // })
            if(init){
                init = false;
                Bakkes.onInitError();
            }
            Bakkes.onEvent();
        });
        Bakkes.webSocket.addEventListener("close", function (event) {
            // console.log("ws closed", {
            //     state: Bakkes.state,
            //     leaving: leaving,
            //     event: event
            // });
            if (leaving) {
                return
            }
            if (Bakkes.state === "ready") {
                Bakkes.onClose();
            }
            Bakkes.state = "closed";
            first = false;
            init = false;
            setTimeout(sniffBakkesViewer, 1e4)
            Bakkes.onEvent();
        });
        Bakkes.webSocket.addEventListener("open", function () {
            // console.log("something is listening on port 20452");
            Bakkes.state = "probed-viewer";
            // console.log("sending available command");
            Bakkes.webSocket.send("rllab_player available")
            init = false;
            Bakkes.onOpen();
            Bakkes.onEvent();
        });
        Bakkes.webSocket.addEventListener("message", function (event) {
            // console.log(event.data);
            if (event.data == "next") {
                Bakkes.onNext();
            }
            
            if (event.data == "previous") {
                Bakkes.onPrevious();
            }
            if (event.data == "true") {
                Bakkes.state = "ready";
            }
            Bakkes.onEvent();
        })
    }
    window.addEventListener("beforeunload", function (event) {
        leaving = true
    });
    setTimeout(sniffBakkesViewer)
}

bakkesViewer();

export default Bakkes