import React, { useState, useEffect } from "react";
import { MenuItem, Select, FormControl, FormControlLabel, Button, Checkbox, Grid, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, } from "@mui/material";
import { Details } from "./TargetConst";
import TargetDetail from "./TargetDetail";

const Target = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (props.value.target !== "") {
            setOpen(true);
        }
    }
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const updatedValue = { ...props.value, [e.target.name]: e.target.value, detail: Details[e.target.value] }
        props.setValue(updatedValue)
    }

    const handleChangeDetail = (groupIndex, itemIndex, e) => {
        const updatedValue = { ...props.value }
        updatedValue.detail[groupIndex].items[itemIndex].checked = e.target.checked
        props.setValue(updatedValue)
    }

    return (
        <Grid container>
            <Grid item>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        value={props.value.target}
                        name={"target"}
                        onChange={handleChange}
                        error={props.value.target === ""}
                    >
                        <MenuItem value={"musty"}>Musty (Breezi) Flick</MenuItem>
                        <MenuItem value={"kickoff"}>Kickoff</MenuItem>
                        {/* <MenuItem value={"counter"}>Counter Attack</MenuItem> */}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item alignItems="stretch" style={{ display: "flex" }}>
                <Button variant="outlined" sx={{ m: 1, minWidth: 120 }} onClick={handleOpen}>Detail</Button>
                <TargetDetail open={open} onClose={handleClose} target={props.value} handleChange={handleChangeDetail}/>
                {/* <Dialog open={open} onClose={handleClose}>
                    <DialogTitle color="primary">Musty (Breezi) Flick</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="h6">Additional Conditions</Typography>
                        <Box sx={{ p: 1, pb: 2 }}>
                            <DialogContentText>
                                {props.value.detail.map((detail, index) => (
                                    <FormControlLabel key={index} control={<Checkbox checked={detail.checked} name={detail.name} key={index} onChange={(e) => handleChangeDetail(index, e)} />} label={detail.name} />
                                ))}
                            </DialogContentText>
                        </Box>
                        <Typography variant="h6">Detection Accuracy</Typography>
                        <Box sx={{ p: 1, pb: 2 }}>
                            <DialogContentText>
                                Precision: ~80%
                            </DialogContentText>
                            <DialogContentText>
                                Recall: ~95%
                            </DialogContentText>
                        </Box>
                        <Typography variant="h6">Detection Conditions</Typography>
                        <Box sx={{ p: 1, pb: 2 }}>
                            <Box>
                            <DialogContentText>
                                It's detected when satisfying following six conditions.
                            </DialogContentText>
                            <Box component="ol" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
                                <Box component="li">The flip is not side flip and front flip.</Box>
                                <Box component="li">The angle formed by a vector (0, 0, 1) and the direction of the car top at the start of the flip is 0° to 90°.</Box>
                                <Box component="li">The angle formed by the car movement diredtion and the direction to the car front at the start of the flip is 90° to 180°.</Box>
                                <Box component="li">The angle formed by a vector (0, 0, 1) and the direction to the car left side at the start of the flip is 45° to 135°.</Box>
                                <Box component="li">The angle formed by the ball movement direction and the car's one at the start of the flip is 0° to 90°.</Box>
                                <Box component="li">The angle formed by the direction to the ball from the car and the direction to the car top at the hit is 0° to 30°.</Box>
                            </Box>

                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog> */}
            </Grid>
        </Grid>
    );
}

export default Target;