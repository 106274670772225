import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from '@mui/material';
import { FaPatreon, FaYoutube } from 'react-icons/fa';
import { orange } from '@mui/material/colors';

export default function ButtonAppBar() {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{
            flexGrow: 1, display: "flex"
          }}>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                display: 'flex',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'orange',
                textDecoration: 'none',
                alignItems: "center",
              }}
            >
              <Box src="logo4.png" component="img" sx={{ maxHeight: "24px", pr: 1 }}></Box>
              <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>RLLab</Box>
            </Typography>
          </Box>
          <Box sx={{ pr: { xs: 0, md: 8 }, display: "flex" }}>
            <Button
              href='https://bakkesplugins.com/plugins/view/344' target="_blank" rel="noopener noreferrer" size='large'
              sx={{ color: 'black', display: { xs: 'none', md: 'flex' }, textTransform: 'none', m: 1, px: 2, py: 0.5, fontWeight: "normal", backgroundColor: orange[600], "&:hover": { backgroundColor: orange[400]}}}
            >
              BakkesPlugin
            </Button>
            <Button
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              sx={{ color: 'white', px: 1, py: 0, minWidth: 0, minHeight: 0, m: 1, textTransform: 'none' }}
              href="https://www.youtube.com/channel/UC61aWViG1F1-X-qK5y3ryHg" target="_blank" rel="noopener noreferrer"
            >
              <FaYoutube color="#FF0000" size="30" sx={{ display: 'flex' }} />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, pl: 1 }}>YouTube</Box>
            </Button>
            <Button
              href='https://www.patreon.com/rllab' target="_blank" size='large' rel="noopener noreferrer"
              sx={{ color: 'white', px: 1, py: 0, minWidth: 0, minHeight: 0, m: 1, textTransform: 'none' }}
            >
              <FaPatreon color='#FF424D' size="18" sx={{ display: 'flex' }} />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, pl: 1 }}>Become a patron</Box>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}