import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Snackbar, Alert, AlertTitle, Link, Button } from "@mui/material";
import Game from "./Game";
import Bakkes from "./Bakkes"

const Games = (props) => {
  const [replays, setReplays] = useState()
  const [details, setDetails] = useState({})
  const [displayed, setDisplayed] = useState(-1);
  const [update, setUpdate] = useState({});
  const [snack1Open, setSnack1Open] = useState(false);
  const [snack2Open, setSnack2Open] = useState(false);
  const [snack3Open, setSnack3Open] = useState(false);

  const handleSnack1Close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack1Open(false);
  };
  const handleSnack2Close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack2Open(false);
  };
  const handleSnack3Close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack3Open(false);
  };

  useEffect(() => {
    if (displayed >= 0) {
      const replay = replays[displayed]
      replays[displayed].isPlayed = true
      setReplays([...replays])

      const detail = details[replay.rocket_league_id]
      const json = detail.frames.map(a => {
        const time = (a.is_overtime ? "+" : null) + (Math.floor(a.seconds_remaining / 60)) + ":" + String(a.seconds_remaining % 60).padStart(2, '0');
        const name = detail.players.blue.find(player => player.id == a.player)?.name || detail.players.orange.find(player => player.id == a.player)?.name;
        return { "player": "Steam|" + a.player + "|0", "frame": a.frame, "name": name, "time": time }
      });
      try {
        Bakkes.webSocket.send("rllab_player " + replay.id + " " + JSON.stringify(json));
      } catch {

      }
    }
  }, [displayed])

  Bakkes.onNext = () => {
    const nextDisplayed = (displayed + 1) % replays.length;
    setDisplayed(nextDisplayed);
  }
  Bakkes.onPrevious = () => {
    const prevDisplayed = (displayed + replays.length - 1) % replays.length;
    setDisplayed(prevDisplayed);
  }
  Bakkes.onEvent = () => {
    setUpdate({})
  }
  Bakkes.onInitError = () => {
    setSnack1Open(true)
  }
  Bakkes.onOpen = () => {
    setSnack2Open(true)
  }

  Bakkes.onClose = () => {
    setSnack3Open(true)
  }

  useEffect(() => {
    const filtered_details = { ...props.details }

    for (const key in filtered_details) {
      filtered_details[key] = filterFramesFromDetail({ ...filtered_details[key] })
    }

    const filtered_replays = props.replays.filter((replay) => {
      return props.filter.showAll || filtered_details[replay.rocket_league_id].frames?.length > 0
    })

    setDetails(filtered_details);
    setReplays(filtered_replays);
    setDisplayed(-1);
  }, [props])

  function filterFramesFromDetail(detail) {
    let frames = detail.frames;

    if (frames) {
      frames = frames.filter(frame => {
        const team = props.filter.showOpponent ? (frame.team == "orange" ? "blue" : "orange") : frame.team;
        let team_matched = true;
        if (props.filter.team) {
          team_matched = detail.teams[team].match(props.filter.team);
        }

        let player_matched = true;
        if (props.filter.player) {
          player_matched = detail.players["orange"].find(a => a.id == frame.player && a.name.match(props.filter.player)) || detail.players["blue"].find(a => a.id == frame.player && a.name.match(props.filter.player))
        }

        return team_matched && player_matched
      })

      for (const detail of props.target.detail) {
        const isAll = detail.items.every((detail) => !detail.checked)
        if (!isAll) {
          frames = frames.filter(frame => {
            return detail.items.every((item) => !item.checked) || detail.items.some(item => item.checked && (detail.key ? frame[detail.key] == item.name : frame[item.name]));
          });
        }
      }

      frames = frames.sort((a, b) => a.frame - b.frame)
      detail.frames = frames;
    }
    return detail
  }

  return (
    <Grid container spacing={0.5}>
      <Snackbar open={snack1Open} autoHideDuration={6000} onClose={handleSnack1Close} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSnack1Close} severity="warning" sx={{ width: '100%', display: { xs: 'none', md: 'flex' } }} elevation={6} variant="filled">
          <AlertTitle>Connenction failed</AlertTitle>
          Please install our bakkesmod plugin<br />
          and ensure that the game is running and the plugin is active.<br />
          <Button href="https://bakkesplugins.com/plugins/view/344" target="_blank" size="small" sx={{ backgroundColor: "#444444", color: "white", textTransform: "none", "&:hover": { backgroundColor: "#666666" } }}>Install</Button>
        </Alert>
      </Snackbar>
      <Snackbar open={snack2Open} autoHideDuration={6000} onClose={handleSnack2Close} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSnack2Close} severity="success" sx={{ width: '100%', display: { xs: 'none', md: 'flex' } }} elevation={6} variant="filled">
          <AlertTitle>Connection successful</AlertTitle>
          Click on the play button to watch the target scene.<br />
          The scene will be played on the game replay mode.
        </Alert>
      </Snackbar>
      <Snackbar open={snack3Open} autoHideDuration={6000} onClose={handleSnack3Close} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSnack3Close} severity="info" sx={{ width: '100%', display: { xs: 'none', md: 'flex' } }} elevation={6} variant="filled">
          <AlertTitle>Connection lost</AlertTitle>
          Please ensure that the game is running and the plugin is active.
        </Alert>
      </Snackbar>
      {replays && (replays.map((replay, index) => (
        <Game key={index} index={index} replay={replay} detail={details[replay.rocket_league_id]} Bakkes={Bakkes} setDisplayed={setDisplayed}></Game>
      )))}
    </Grid>
  );
};

export default Games;