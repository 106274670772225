import React, { useState, useEffect } from "react";
import { IconButton, Box, Dialog, DialogTitle, DialogContent, DialogContentText, } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const TargetDetailInfo = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return (
        <Box component="span">
            <IconButton onClick={handleOpen} sx={{ p: 0, verticalAlign: "baseline" }}>
                <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle color="primary">{props.title}</DialogTitle>
                <DialogContent dividers>
                <DialogContentText>
                    {props.description}
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default TargetDetailInfo;